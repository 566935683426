import React from 'react'
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { routes } from '../common/config/routes'

function RequireAuth({ children, isLogin, isAuth }: { children: JSX.Element; isLogin: boolean; isAuth: boolean }) {
  const location = useLocation()

  if (isAuth && !isLogin) {
    console.log('Nao autenticado')
    return <Navigate to="/Login" state={{ from: location }} replace />
  } else {
    return children
  }
}
export const RoutesApp = (): JSX.Element => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {routes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <RequireAuth isAuth={route.auth} isLogin={true}>
                    <route.component />
                  </RequireAuth>
                }
              />
            )
          })}
        </Routes>
      </BrowserRouter>
    </>
  )
}
