import styled from 'styled-components'
import { device } from '../../assets/styles/global'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;

  height: 90vh;
  min-width: 70vw;
  position: relative;
  @media (${device.mobileML}) {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
`
export const ContainerVideo = styled.div`
  justify-self: stretch;
  display: flex;
  margin: 2rem;
  flex: 1;
  position: relative;
  border-radius: 2rem;
  overflow: hidden;
  box-shadow: 0 0 10rem ${({ theme }) => theme.colors.pallets.e};
  @media (${device.mobileML}) {
    margin: 2rem 0;
    order: 1;
  }
`
export const Button = styled.button`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.pallets.e};
  border: none;
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 99998;
  transition: 0.5s ease-in all;
  > * {
    color: ${({ theme }) => theme.colors.pallets.d};
    font-size: 2rem;
  }
  :disabled {
    opacity: 0.2;
    filter: blur(0.2);
  }
  @media (${device.mobileML}) {
    order: 2;

    :last-child {
      align-self: flex-end;
      transform: translateY(-100%);
    }
  }
`
export const LogoCircular = styled.img`
  width: 12rem;
  height: 12rem;
  z-index: 99997;
  position: absolute;
  right: 5rem;
  top: 5rem;
  @media (${device.mobileML}) {
    width: 7rem;
    height: 7rem;
    right: 0.5rem;
    top: 7rem;
  }
`
