import styled from 'styled-components'
import { device } from '../../../assets/styles/global'

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.pallets.e};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 7rem;
  padding: 0 5vw;

  @media (${device.laptopM}) {
    height: 4rem;
  }
  @media (${device.mobileL}) and (${device.laptopM}) {
    height: 3rem;
  }
`
export const Copywrite = styled.span`
  color: ${({ theme }) => theme.colors.pallets.c};
  width: 15rem;
  font-weight: 100;
  font-size: 0.8em;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  @media (${device.mobileML}) {
    display: none;
  }
  @media (${device.mobileL}) and (${device.laptopM}) {
    font-size: 0.5em;
  }
`
export const LogoBola = styled.div`
  width: 3.75rem;
  @media (${device.mobileML}) {
    width: 3rem;
  }
  @media (${device.mobileL}) and (${device.laptopM}) {
    width: 2.8rem;
  }
`
export const ContainerSocialMidia = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 15rem;
  > a {
    color: ${({ theme }) => theme.colors.pallets.c};
    font-size: 2rem;
    :hover {
      color: ${({ theme }) => theme.colors.pallets.b};
    }
  }
`
