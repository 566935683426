import { transparentize } from 'polished'
import styled from 'styled-components'

export const Container = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 999999;
  background: ${() => transparentize(0.2, '#000')};
  display: ${(p) => (!p.isOpen ? 'none' : '')};
`
