import React, { useState } from 'react'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import { Images } from '../../assets/images'
import { VideoPlayer } from '../VideoPlayer'
import { Button, Container, ContainerVideo, LogoCircular } from './styles'

interface VideoCarouselProps {
  videos: string[]
}

export const VideoCarousel: React.FC<VideoCarouselProps> = ({ videos }) => {
  const [atualVideo, setAtualVideo] = useState<number>(0)
  const changeVideo = (index: number) => {
    setAtualVideo(index)
  }

  return (
    <Container>
      <LogoCircular src={Images.logoNomeCirculoClaro} alt={'Logo Vale da Salvação'} />

      <Button disabled={atualVideo <= 0} onClick={() => changeVideo(atualVideo - 1)}>
        <FaAngleLeft />
      </Button>

      <ContainerVideo>
        <VideoPlayer
          type={'youtube'}
          bgOnPlaying={'rgba(0,0,0,0.1)'}
          width={'100%'}
          height={'60vh'}
          urlVideo={videos[atualVideo]}
          playingTime={0}
        />
      </ContainerVideo>

      <Button disabled={atualVideo >= videos.length - 1} onClick={() => changeVideo(atualVideo + 1)}>
        <FaAngleRight />
      </Button>
    </Container>
  )
}
