import { ImagesProps } from '../../interfaces/images'
import fotoPastor from './fotoPastor.png'
import logoBolaBegePequena from './logoBolaBege.png'
import logoBolaBranca from './logoBolaBranca.svg'
import logoBolaBrancaPequena from './logoBolaBrancaPequena.svg'
import logoBolaPreta from './logoBolaPreta.svg'
import logonomeClaro from './logoNome.svg'
import logoNomeCirculoClaro from './logoNomeCirculoClaro.svg'
import logoHorizontal from './logotipo_horizontal.svg'
import logotipoClaro from './logotipo_simbolo.svg'
import logotipoClaro1 from './logotipo_simbolo_claro.svg'
import logotipoEscuro from './logotipo_simbolo_escuro.svg'

export const Images: ImagesProps = {
  logoHorizontal,
  painelPrincipal: fotoPastor,
  logoBolaBrancaPequena,
  logoBolaBranca,
  logoBolaPreta,
  logotipoClaro,
  logotipoClaro1,
  logotipoEscuro,
  logoBolaBegePequena,
  logonomeClaro,
  logoNomeCirculoClaro,
}
