import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import foto from '../../../common/assets/images/fotoPastor.png'
// import Video from '../../common/assets/videos/CRISOk..mp4'
import {
  Footer,
  Header,
  Section,
  ToDownButton,
  VideoCarousel,
  VideoPlayer,
  WhatsappButton,
} from '../../../common/components'
import { ProgressProps } from '../../../common/components/VideoPlayer'
import Dados from '../../../common/config/dados.json'
import { ContainerDepoimentos, TituloSection } from '../../home/styles'
import { ContainerPage, ContainerSection2, ConteudoSection2 } from './styles'

export const ManhuacuTestemunho = () => {
  // const scrollPage = useScroll()

  const [duration, setDuration] = useState(0)
  const [progress, setProgress] = useState<ProgressProps>({} as ProgressProps)
  const [playingTime, setPlayingTime] = useState(0)
  const [durationPercent, setDurationPercent] = useState(0)
  const [exibeWhatsVideo, setExibeWhatsVideo] = useState(false)
  // useEffect(() => console.log(scrollPage.scrollOffsetY), [scrollPage.scrollOffsetY])
  const handlePercentPlaying = (duration: number) => {
    if (duration >= 80) {
      setExibeWhatsVideo(true)
    } else {
      setExibeWhatsVideo(false)
    }
  }
  const handleScroll = () => {
    document.getElementById('btScrollMeio')?.click()
    /* scroller.scrollTo('meio', {
      duration: 500,
      delay: 0,
      smooth: true,
      offset: 0, // Scrolls to element + 50 pixels down the page
    }) */
  }
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  useEffect(() => {
    setPlayingTime(progress?.playedSeconds)
  }, [isPortrait])

  return (
    <ContainerPage className={'p-0'} fluid>
      <Header />

      <Section id="painel">
        <VideoPlayer
          urlVideo={`${
            !isPortrait
              ? `${Dados.manhuacu.urlS3}/Testemunho/horizontal.mp4`
              : `${Dados.manhuacu.urlS3}/Testemunho/vertical.mp4`
          }`}
          urlCapa={foto}
          labelPlayer={'Clique no Play e deixe Jesus falar ao seu coração!'}
          bgOnPlaying={'#030303'}
          height={'100vh'}
          playing
          playingTime={playingTime}
          controls={false}
          onEnded={() => {
            setExibeWhatsVideo(false)
            handleScroll()
          }}
          onProgress={(progress) => setProgress(progress)}
          onDuration={(duration) => setDuration(duration)}
          percentPlaying={(duration) => {
            setDurationPercent(duration)
            handlePercentPlaying(duration)
          }}
        />
        <ToDownButton id={'btScrollMeio'} bgColor={'#E8AA67'} colorIcon={'#110E0A'} url={'meio'} direction="down" />
      </Section>

      <Section id="meio" bgColor="#ffe7bd">
        <ContainerSection2>
          <ConteudoSection2>
            <h2>Comunhão gera semelhança.</h2>
            <main>
              <p>
                O próximo passo rumo à sua caminhada de mudança genuína de vida é ter contato com quem já teve a própria
                vida transformada por Jesus.
              </p>
              <p>
                Sim! Nós queremos te auxiliar nesse processo. Não somos perfeitos, mas seguimos Aquele que é perfeito, e
                que te ama.
              </p>
              <p>
                Toque no botão abaixo e fique à vontade para enviar uma mensagem, fazer seu pedido de oração ou tirar
                suas dúvidas.
              </p>
            </main>
            <WhatsappButton
              visible={true}
              bgButton={'#392C23'}
              bgButtonHover={'##1C130E'}
              colorIcone={'#E8AA67'}
              colorIconeHover={'#f2b778'}
              numeroCel={Dados.manhuacu.whatssapp.testemunhos.numero}
              mensagem={Dados.manhuacu.whatssapp.testemunhos.mensagem}
            />
            <h3>Deus te abençoe!</h3>
          </ConteudoSection2>
        </ContainerSection2>
      </Section>
      <Section id="depoimetos" bgColor="#382B22">
        <ContainerDepoimentos>
          <TituloSection>
            <h3>Depoimentos</h3>
            <span>Veja o que Deus fez na vida dessas pessoas, ele pode fazer na sua vida também…</span>
          </TituloSection>
          <VideoCarousel videos={Dados.ipatinga.depoimentos} />
          <Footer cidade={Dados.manhuacu.nome} />
        </ContainerDepoimentos>
      </Section>
    </ContainerPage>
  )
}
