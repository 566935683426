import { useAnimation } from 'framer-motion'
import React, { useEffect } from 'react'
import { FaWhatsapp } from 'react-icons/fa'
import { ButtonWhatsapp, Container } from './styles'

interface WhatsappButtonProps {
  numeroCel: string
  mensagem: string
  bgButton?: string
  bgButtonHover?: string
  colorIcone?: string
  colorIconeHover?: string
  visible: boolean
}

export const WhatsappButton: React.FC<WhatsappButtonProps> = ({
  visible,
  numeroCel,
  mensagem,
  bgButton = '#000',
  bgButtonHover = '#000',
  colorIcone = '#FFF',
  colorIconeHover = '#fff',
}) => {
  const controls = useAnimation()
  const url = 'https://wa.me'
  const numberFormated = numeroCel.replace(/[^\w\s]/gi, '').replace(/ /g, '')
  const msgFormated = encodeURI(mensagem)
  const urlFormated = `${url}/${numberFormated}?text=${msgFormated}`
  const variants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  }
  const handleEnvioMensagem = () => {
    window.open(urlFormated)
  }
  useEffect(() => {
    visible &&
      controls.start({
        opacity: [0, 1],
        y: [50, 0],
        scale: [2, 1],
        transition: { delay: 1, ease: 'easeIn', duration: 1 },
      })
  }, [visible])

  return (
    <Container initial={'hidden'} animate={controls} variants={variants} bg={bgButton} bghover={bgButtonHover}>
      <ButtonWhatsapp icone={colorIcone} iconehover={colorIconeHover} bg={bgButton} onClick={handleEnvioMensagem}>
        <FaWhatsapp />
        <div />
      </ButtonWhatsapp>
    </Container>
  )
}
