import React from 'react'
import { AiFillYoutube, AiOutlineInstagram } from 'react-icons/ai'
import { Images } from '../../../assets/images'
import { Container, ContainerSocialMidia, Copywrite, LogoBola } from './styles'

interface FooterProps {
  cidade?: string
}

export const Footer: React.FC<FooterProps> = ({ cidade = 'Ipatinga' }) => {
  return (
    <Container>
      <Copywrite>© BOLA DE NEVE {cidade}</Copywrite>

      <LogoBola>
        <img src={Images.logoBolaBegePequena} alt="Logo Bola de Neve"></img>
      </LogoBola>

      <ContainerSocialMidia>
        <a href="https://www.youtube.com/c/valedasalvacao" title="Acessar Youtube" target={'_blank'} rel={'noreferrer'}>
          <AiFillYoutube />
        </a>
        <a
          href="https://www.instagram.com/valedasalvacao"
          title="Acessar Instagram"
          target={'_blank'}
          rel={'noreferrer'}>
          <AiOutlineInstagram />
        </a>
      </ContainerSocialMidia>
    </Container>
  )
}
