import { motion } from 'framer-motion'
import styled from 'styled-components'
import { device } from '../../assets/styles/global'

interface StyleContainerWhats {
  bg?: string
  bghover?: string
}
interface StyleButtonWhats {
  icone?: string
  iconehover?: string
  bg?: string
}

export const Container = styled(motion.div).attrs(() => ({}))<StyleContainerWhats>`
  position: relative;
  margin: 3rem;
  transition: 0.4s ease-in background-color;
  z-index: 100;
  align-self: center;

  @media (${device.mobileML}) {
    align-self: flex-start;
    margin: 1rem;
  }
  @media (${device.mobileL}) and (${device.laptopM}) {
    margin: 0.5rem;
  }
`

export const ButtonWhatsapp = styled.button<StyleButtonWhats>`
  background-color: ${(p) => p.bg};
  border-radius: 50%;
  padding: 0.5rem;
  border: none;
  display: flex;
  justify-items: center;
  align-items: center;
  z-index: 5;
  :hover * {
    color: ${(p) => p.iconehover};
  }

  > * {
    transition: 0.4s ease-in all;
    font-size: 70px;
    color: ${(p) => p.icone};
    z-index: 4;
    @media (${device.laptopM}) {
      font-size: 50px;
    }
  }
  //ponta
  > div {
    transition: 0.4s ease-in all;
    position: absolute;
    left: 3px;
    bottom: 3px;
    background-color: ${(p) => p.bg};
    width: 30px;
    height: 30px;
    z-index: 3;
    border-radius: 2px;
  }
`
