/* eslint-disable multiline-ternary */
/* eslint-disable import/no-named-default */
import React, { useRef, useState } from 'react'
import { BiPause, BiPlay } from 'react-icons/bi'
import { default as _ReactPlayer } from 'react-player/lazy'
import { ReactPlayerProps } from 'react-player/types/lib'
import { Loading } from '../index'
import { ButtonPlay, Container, ContainerPlayBar, PlayerIconVideo, ProgressBarVideo } from './styles'
export interface ProgressProps {
  played: number
  playedSeconds: number
  loaded: number
  loadedSeconds: number
}

interface VideoPlayerProps {
  type?: 'file' | 'youtube'
  playing?: boolean
  playingTime: number
  labelPlayer?: string
  urlVideo: string
  urlCapa?: string
  bgOnPlaying?: string
  controls?: boolean
  width?: string
  height?: string
  pip?: boolean
  light?: boolean // se passar url da imagem ela fica de fundo
  onEnded?: () => void
  onDuration?: (duration: number) => void
  onProgress?: (progress: ProgressProps) => void
  onPlay?: () => void
  onStart?: () => void
  onPause?: () => void
  onStop?: () => void
  onReady?: () => void
  percentPlaying?: (duration: number) => void
}

export const VideoPlayer: React.FC<VideoPlayerProps> = ({
  type = 'file',
  urlVideo,
  urlCapa,
  labelPlayer = 'Aperte Play',
  bgOnPlaying,
  playing = true,
  playingTime = 0,
  controls = false,
  pip = false,
  light = false,
  width = '100vw',
  height = '100vh',
  onStart,
  onPlay,
  onPause,
  onEnded,
  onDuration,
  onProgress,
  onReady,
  percentPlaying,
}) => {
  const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>
  const reactPlayerRef = useRef<_ReactPlayer>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [isPlaying, setIsPlaying] = useState<boolean>(false)
  const [isBuffering, setIsBuffering] = useState<boolean>(false)
  const [durationPlayer, setDurationPlayer] = useState<number>(0)

  const handleEnded = () => {
    light && reactPlayerRef.current?.showPreview()
    onEnded && onEnded()
  }
  const handlePlay = () => {
    setIsPlaying(true)
    if (type === 'file') {
      reactPlayerRef.current?.seekTo(playingTime, 'seconds')
    }
    onPlay && onPlay()
  }
  const handlePause = () => {
    light && reactPlayerRef.current?.showPreview()
    setIsPlaying(false)
    onPause && onPause()
  }
  const handleProgress = (progress: ProgressProps) => {
    const duration: number = reactPlayerRef.current?.getDuration() || 1
    const playing: number = reactPlayerRef.current?.getCurrentTime() || 1
    const percent: number = Math.floor((playing / duration) * 100)
    setDurationPlayer(percent)
    percentPlaying && percentPlaying(percent)
    onProgress && onProgress(progress)
  }

  const getBgVideo = (): string => {
    return isPlaying && bgOnPlaying
      ? `background-color:${bgOnPlaying}`
      : `background-color:${bgOnPlaying}; ${light && `&& background - image: url('${urlCapa}')`}`
  }
  const handleBuffer = (on: boolean) => {
    setIsBuffering(on)
  }

  return (
    <Container width={width} bg={getBgVideo()} onClick={() => (!light && !isPlaying ? handlePlay() : handlePause())}>
      <Loading open={isBuffering} />
      {type === 'file' && (
        <ContainerPlayBar>
          {!light && !isPlaying ? (
            <ButtonPlay ref={buttonRef} onClick={handlePlay}>
              <BiPlay />
            </ButtonPlay>
          ) : (
            <ButtonPlay ref={buttonRef} onClick={handlePause}>
              <BiPause />
            </ButtonPlay>
          )}
          <ProgressBarVideo now={durationPlayer} />
        </ContainerPlayBar>
      )}

      <ReactPlayer
        ref={reactPlayerRef}
        style={{ zIndex: 9980 }}
        url={urlVideo}
        playing={isPlaying}
        playIcon={<PlayerIconVideo />}
        controls={controls}
        pip={pip}
        light={light}
        width={width}
        height={height}
        onReady={onReady}
        onStart={onStart}
        onPlay={handlePlay}
        onProgress={(progress) => handleProgress(progress)}
        onDuration={(duration) => onDuration && onDuration(duration)}
        onEnded={handleEnded}
        onPause={handlePause}
        onBuffer={() => handleBuffer(true)}
        onBufferEnd={() => handleBuffer(false)}
        config={{
          youtube: {
            playerVars: { showinfo: 0 },
          },
        }}
      />
    </Container>
  )
}
