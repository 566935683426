import React from 'react'
import { ThemeProvider } from 'styled-components'
import { GlobalStyle } from '../common/assets/styles/global'
import Themes from '../common/assets/styles/themes'
import { RoutesApp } from './Routes'

function App(): JSX.Element {
  return (
    <>
      <ThemeProvider theme={Themes.defaultTheme}>
        <GlobalStyle />
        <RoutesApp />
      </ThemeProvider>
    </>
  )
}

export default App
