import ValeSansBold from './velasans/VelaSans-Bold.woff'
import ValeSansExtraBold from './velasans/VelaSans-ExtraBold.woff'
import ValeSansExtraLight from './velasans/VelaSans-ExtraLight.woff'
import ValeSansLight from './velasans/VelaSans-Light.woff'
import ValeSansMedium from './velasans/VelaSans-Medium.woff'
import ValeSansRegular from './velasans/VelaSans-Regular.woff'
import ValeSansSemiBold from './velasans/VelaSans-SemiBold.woff'

export const Fonts = {
  ValeSansRegular,
  ValeSansBold,
  ValeSansExtraBold,
  ValeSansLight,
  ValeSansExtraLight,
  ValeSansMedium,
  ValeSansSemiBold,
}
