import { transparentize } from 'polished'
import { ProgressBar } from 'react-bootstrap'
import { FaRegPlayCircle } from 'react-icons/fa'
import styled from 'styled-components'
import { device } from '../../assets/styles/global'
interface ContainerProps {
  bg?: string
  width?: string
}
export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  width: ${(p) => p.width || '100%'};
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  cursor: pointer;
  ${(p) => p.bg};

  @media ${device.laptopL} {
    background-size: contain;
  }
`

export const PlayerIconVideo = styled(FaRegPlayCircle)`
  font-size: 7rem;
  border-radius: 50%;
  padding: 0.5rem;
  max-width: 90vw;
  background-color: ${({ theme }) => transparentize(0.4, theme.colors.pallets.e)};
  color: ${({ theme }) => theme.colors.pallets.c};
  filter: drop-shadow(0 0 0.5rem rgb(255 231 189 / 0.4));
  transition: 0.2s ease-in all;
  :hover {
    font-size: 12rem;
    color: ${({ theme }) => theme.colors.pallets.d};
  }
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1000;
`

export const ContainerPlayBar = styled.aside`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  height: 5vh;
  position: absolute;
  bottom: 2.5rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999001;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  @media (${device.mobileML}) {
    background-color: rgba(0, 0, 0, 0.2);
  }
`

export const ButtonPlay = styled.button`
  cursor: pointer;
  border: none;
  background-color: transparent;

  > * {
    font-size: 2.5rem;

    padding: 0.5rem;
    max-width: 50vw;
    /*background-color: ${({ theme }) => transparentize(0.4, theme.colors.pallets.e)};*/
    color: ${({ theme }) => theme.colors.pallets.c};
    /*filter: drop-shadow(0 0 0.5rem rgb(255 231 189 / 0.4));*/
    transition: 0.2s ease-in all;
    :hover {
      color: ${({ theme }) => theme.colors.pallets.d};
    }
  }
  @media ${device.mobileML} {
    top: auto;
    bottom: 15vh;
    left: 50vw;
    transform: translateX(-50%);
  }
`

export const LabelPlayer = styled.label`
  background-color: ${({ theme }) => transparentize(0.6, theme.colors.pallets.e)};
  color: ${({ theme }) => theme.colors.pallets.b};
  position: absolute;
  width: auto;
  padding: 0.5rem;
  top: 2.5rem;
  left: 7.5rem;

  align-self: center;
  border-radius: 0.5rem;
  text-align: center;

  @media ${device.mobileML} {
    top: auto;
    bottom: 4vh;
    left: 0;
    width: calc(100vw - 1rem);
    margin: 0.5rem;
  }
`
export const ProgressBarVideo = styled(ProgressBar)`
  flex: 1;
  margin: 1rem;

  height: 0.2rem;
  background-color: ${({ theme }) => theme.colors.pallets.f};
  > * {
    background-color: ${({ theme }) => theme.colors.pallets.d};
  }
`
