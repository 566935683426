import { motion } from 'framer-motion'
import styled from 'styled-components'
interface ContainerProps {
  bg: string
}

export const Container = styled(motion.div).attrs(() => ({
  whileInView: {
    opacity: [0, 1],

    transition: { delay: 1, ease: 'easeIn', duration: 2.5 },
  },
  exit: {
    opacity: [1, 0],

    transition: { delay: 1, ease: 'easeIn', duration: 0.5 },
  },
  onViewportEnter: () => console.log('èntrou'),
  onViewportLeave: () => console.log('saiu'),
}))<ContainerProps>`
  display: flex;
  flex: 1;
  width: 100%;

  background-position: top center;
  background-attachment: fixed;
  background-size: cover;
  background-image: ${(p) => `url('${p.bg}')`};
`
