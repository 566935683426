import { motion } from 'framer-motion'
import { transparentize } from 'polished'
import { Navbar } from 'react-bootstrap'
import styled from 'styled-components'
import { device } from '../../../assets/styles/global'

export const NavTop = styled.div`
  display: flex;
  width: 100vw;
  align-items: center;
  justify-content: space-between;
  padding: 0 5vw;

  background-color: #382b22;
  box-shadow: #211911 0 0 2rem;
  height: 4.7rem;
  position: fixed;
  top: 0;
  z-index: 999900;
  a {
    text-transform: uppercase;
    color: #ffe7bd !important;
    font-size: 0.85em;
    &.active {
      color: #efc68f !important;
    }
    &:hover {
      color: #efc68f !important;
    }
    transition: all ease-in 0.5s;
  }
  box-shadow: #211911 0 0 2rem;
  @media (${device.mobileML}) {
    justify-content: flex-start;
  }
  > div {
    display: flex;
  }
  > div:last-child {
    flex: 1;
  }
`

export const LogoMarca = styled(motion.div).attrs(() => ({
  animate: {
    scale: [1, 1],
    opacity: [0, 1],
    y: [-100, 0],
  },
  transition: { delay: 1, ease: 'easeOut', duration: 1.5 },
}))`
  > img {
    width: 22rem;
    margin-left: -2rem;
  }
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  @media (${device.mobileML}) {
    flex: 0;
    justify-content: start;
    > img {
      height: 1.5rem;
      width: auto;

      margin-left: 0.5rem;
    }
  }
`
export const LogoTipo = styled(motion.img).attrs(() => ({
  animate: {
    scale: [1, 1],
    opacity: [0, 1],
    y: [-100, 0],
  },
  transition: { delay: 1, ease: 'easeOut', duration: 1.5 },
}))`
  height: 2.6rem;
  @media (${device.mobileML}) {
    height: 2rem;
  }
`
export const NavbarToggle = styled(Navbar.Toggle)`
  margin: 0.5rem 2rem 0.5rem 1.5rem;
  background-color: transparent;
  border: 1px solid ${() => transparentize(0.8, '#ffe7bd')} !important;
  > * {
    color: ${({ theme }) => theme.colors.pallets.c};
  }
`
export const NavbarCollapse = styled(Navbar.Collapse)`
  padding: 0.25rem 1.5rem;
`
