import { Container } from 'react-bootstrap'
import styled from 'styled-components'
import { Images } from '../../common/assets/images'
import { device } from '../../common/assets/styles/global'

export const ContainerPage = styled(Container)`
  padding: 0;
`
export const ContainerSection2 = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: top;
  margin-top: 4.9rem;
  min-height: calc(100vh - 4.9rem);
  width: 100vw;
  background: url(${Images.logotipoClaro1}) fixed no-repeat -20vw -14vh,
    url(${Images.logotipoClaro1}) fixed no-repeat 63vw 40vh;
  background-color: #ffe7bd;
  background-size: 80vh 80vh;

  @media (${device.mobileML}) {
    background: none;
    background-color: #ffe7bd;
  }
`

export const ConteudoSection2 = styled.div`
  padding: 4rem 29vw;
  flex: 1;
  display: flex;
  flex-direction: column;
  @media (${device.mobileML}) {
    padding: 3rem 15vw;
  }
  @media (${device.mobileL}) and (${device.laptopM}) {
    padding: 1rem 15vw;
  }
  > h2 {
    font-size: 4.25rem;
    font-weight: bolder;
    text-align: center;
    color: ${({ theme }) => theme.colors.pallets.e};

    @media (${device.mobileML}) {
      font-size: 2rem;
      line-height: 2rem;

      text-align: left;
    }
    @media (${device.mobileL}) and (${device.laptopM}) {
      font-size: 1.5rem;
      text-align: center;
    }
  }
  > h3 {
    font-size: 2.5rem;

    font-weight: bolder;
    text-align: center;
    color: ${({ theme }) => theme.colors.pallets.e};
    @media (${device.mobileML}) {
      font-size: 1.5rem;
      line-height: 1.5rem;
      width: 100%;
      text-align: left;
    }
    @media (${device.mobileL}) and (${device.laptopM}) {
      font-size: 1rem;
      line-height: 1rem;
      width: 100%;
      text-align: center;
      margin-top: 0.5rem;
    }
  }
  > main {
    font-family: 'ValeSansRegular';
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    font-size: 1.5rem;
    line-height: 2rem;
    color: ${({ theme }) => theme.colors.pallets.e};
    text-align: center;
    margin: 3rem 0;
    @media (${device.mobileML}) {
      font-size: 1rem;
      line-height: 1.4rem;
      width: 100%;
      text-align: left;
      margin: 1rem 0;
    }
    @media (${device.mobileL}) and (${device.laptopM}) {
      font-size: 0.8rem;
      line-height: 0.8rem;
      text-align: center;
    }
  }
`
export const ContainerDepoimentos = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: top;
  min-height: calc(100vh - 4.9rem);
  width: 100vw;
  background: url(${Images.logotipoEscuro}) scroll no-repeat center -20vh;
  background-size: 200vh 200vh;
  padding-top: 2rem;
  @media (${device.mobileML}) {
    background: none;
  }
`

export const TituloSection = styled.div`
  width: 90vw;
  height: 5rem;

  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  > h3 {
    color: ${({ theme }) => theme.colors.pallets.a};
    text-transform: uppercase;
    margin-right: 0.5rem;
  }
  > span {
    color: ${({ theme }) => theme.colors.pallets.d};
  }
`
