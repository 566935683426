import {
  GVPage,
  HomePage,
  IpatingaTestemunho,
  ManhuacuPage,
  ManhuacuTestemunho,
  TimoteoPage,
  TimoteoTestemunho,
} from '../../pages'
import { TeoPage } from '../../pages/teofilo-otoni'

export interface IRoute {
  path: string
  name: string
  exact?: boolean
  component: () => JSX.Element
  props?: any
  auth: boolean
  level?: number
  isLogado?: boolean
  isMenu?: boolean
}

// Pages

export const routes: IRoute[] = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
    exact: true,
    auth: false,
    isMenu: false,
  },
  {
    path: '/ipatinga/testemunhos',
    name: 'Testemunhos Ipatinga',
    component: IpatingaTestemunho,
    exact: true,
    auth: false,
    isMenu: false,
  },
  {
    path: '/timoteo',
    name: 'Timoteo',
    component: TimoteoPage,
    exact: true,
    auth: false,
    isMenu: false,
  },
  {
    path: '/timoteo/testemunhos',
    name: 'Timoteo',
    component: TimoteoTestemunho,
    exact: true,
    auth: false,
    isMenu: false,
  },
  {
    path: '/governador_valadares',
    name: 'Governador Valadares',
    component: GVPage,
    exact: true,
    auth: false,
    isMenu: false,
  },
  {
    path: '/governador_valadares/testemunhos',
    name: 'Governador Valadares',
    component: GVPage,
    exact: true,
    auth: false,
    isMenu: false,
  },
  {
    path: '/manhuacu',
    name: 'Manhuaçu',
    component: ManhuacuPage,
    exact: true,
    auth: false,
    isMenu: false,
  },
  {
    path: '/manhuacu/testemunhos',
    name: 'Manhuaçu',
    component: ManhuacuTestemunho,
    exact: true,
    auth: false,
    isMenu: false,
  },
  {
    path: '/teofilo_otoni',
    name: 'Teófilo Otoni',
    component: TeoPage,
    exact: true,
    auth: false,
    isMenu: false,
  },

  {
    path: '*',
    name: 'Error',
    component: HomePage,
    exact: true,
    auth: false,
    isMenu: false,
  },
]
