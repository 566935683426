import React, { useState } from 'react'
import ScrollIntoView from 'react-scroll-into-view'
import { Images } from '../../../assets/images'
import { LogoMarca, LogoTipo, NavTop } from './styles'

export const Header: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  return (
    <NavTop>
      <ScrollIntoView selector={`#painel`} smooth alignToTop>
        <LogoTipo src={Images.logotipoClaro} />
      </ScrollIntoView>
      <ScrollIntoView selector={`#painel`} smooth alignToTop>
        <LogoMarca>
          <img src={Images.logonomeClaro} />
        </LogoMarca>
      </ScrollIntoView>

      {/* <NavbarToggle aria-controls="navbar" onClick={() => setMenuOpen(!menuOpen)}>
        {menuOpen ? <AiOutlineClose /> : <TiThMenu />}
      </NavbarToggle>
      <NavbarCollapse id="navbar" className="justify-content-end">
        <Nav activeKey="/home">
          <Nav.Link href="/home">Home</Nav.Link>
          <Nav.Link href="/quem-somos">Quem Somos</Nav.Link>
          <NavDropdown title="Ações" id="collasible-nav-dropdown" menuVariant="dark">
            <NavDropdown.Item href="/acoes/evengelismo">Evenagelismo</NavDropdown.Item>
            <NavDropdown.Item href="/acoes/restauracao">Restauracao</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
          </NavDropdown>
          <Nav.Link href="/midias">Mídia</Nav.Link>
          <Nav.Link href="/contatos">Contatos</Nav.Link>
        </Nav>
      </NavbarCollapse> */}
    </NavTop>
  )
}
