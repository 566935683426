import React from 'react'
import LoadingImg from '../../assets/images/animations/loading1.json'
import { Animation } from '../../components'
import { Container } from './styles'

interface ILoadingProps {
  open: boolean
}

export function Loading({ open }: ILoadingProps) {
  return (
    <Container isOpen={open}>
      <Animation animation={LoadingImg} />
    </Container>
  )
}
